var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 col-xs-12"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":function($event){return _vm.filterResults()}}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){return _vm.resetResults()}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"couriers","table-id":"couriersTable"},on:{"add-action":function($event){_vm.editDialog = true}}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.couriers,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allCouriers,"items-per-page":-1,"search":_vm.searchTerm,"sort-by":'Courier.title',"calculate-widths":"","dense":"","fixed-header":"","id":"couriersTable","item-key":"Courier.id"},scopedSlots:_vm._u([{key:"item.Courier.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditCourierDialog(item.Courier.id, item.Courier.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateCourier'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteCourier(item.Courier.id, item.Courier.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteCourier')))],1)],1)],1)],1)]}},{key:"item.Courier.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Courier.title))])]}},{key:"item.Courier.tracking_url",fn:function(ref){
var item = ref.item;
return [(item.Courier.tracking_url)?_c('span',[_vm._v(_vm._s(item.Courier.tracking_url))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.na')))])]}}])}),_c('AddCourier',{attrs:{"courier-id":_vm.selectedCourierId,"courier-name":_vm.selectedCourierName,"dialog":_vm.editDialog,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.courierUpdated,"create-done":_vm.courierCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }